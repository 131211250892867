import React from 'react';
import { DefaultSeo } from 'next-seo';
import { LocaleTypes } from '@/types/LocaleTypes';
import { getSeoLocale } from '@/utils/locales/getSeoLocale';
import { googleSearchConsoleConfigLocale } from '@/utils/locales/googleSearchConsoleConfigLocale';
import { generateSeoUrl } from '@/utils/seo/generateSeoUrl';
type Props = {
  locale?: string;
};
export const DefaultSEO = ({
  locale
}: Props) => {
  const additionalMetaTagsArr = [{
    name: 'og:see_also',
    content: 'https://www.pinterest.com/bigcommerce/',
    keyOverride: 'og:see_also_1'
  }, {
    name: 'og:see_also',
    content: 'https://www.instagram.com/bigcommerce/',
    keyOverride: 'og:see_also_2'
  }, {
    name: 'og:see_also',
    content: 'https://www.youtube.com/c/bigcommerce',
    keyOverride: 'og:see_also_3'
  }, {
    name: 'og:see_also',
    content: 'https://www.linkedin.com/company/bigcommerce',
    keyOverride: 'og:see_also_4'
  }, {
    name: 'og:see_also',
    content: 'https://www.facebook.com/BigCommerce/',
    keyOverride: 'og:see_also_5'
  }, {
    name: 'og:see_also',
    content: 'https://twitter.com/BigCommerce',
    keyOverride: 'og:see_also_6'
  }];
  if (locale === LocaleTypes.en_AU) additionalMetaTagsArr.push({
    name: 'og:locale:alternate',
    content: getSeoLocale(LocaleTypes.en_US),
    keyOverride: 'og:locale:alternate_1'
  }, {
    name: 'og:locale:alternate',
    content: getSeoLocale(LocaleTypes.en_GB),
    keyOverride: 'og:locale:alternate_2'
  });
  const googleMetaConfig = locale && googleSearchConsoleConfigLocale[(locale as keyof typeof googleSearchConsoleConfigLocale)];
  if (googleMetaConfig) additionalMetaTagsArr.push(googleMetaConfig);
  return <>
			<DefaultSeo canonical={generateSeoUrl('https://www.bigcommerce.com/')} openGraph={{
      type: 'website',
      url: generateSeoUrl('https://www.bigcommerce.com/'),
      locale: getSeoLocale(locale),
      site_name: 'BigCommerce'
    }} twitter={{
      handle: '@BigCommerce',
      site: '@BigCommerce',
      cardType: 'summary_large_image'
    }} additionalMetaTags={additionalMetaTagsArr} data-sentry-element="DefaultSeo" data-sentry-source-file="DefaultSEO.tsx" />
		</>;
};