import { useState } from 'react';
import { useReportWebVitals } from 'next/web-vitals';
import { SpeedInsights } from '@vercel/speed-insights/next';
import { PageProps, WebVitalsEvent, trackWebVitals } from '@/utils/analytics/trackWebVitals';
import { useConsentContext } from '../OneTrust/ConsentProvider';
import { useAnalyticsContext } from '../Segment/AnalyticsProvider';
export function SpeedInsightsLoadScript() {
  let {
    strictlyNecessary
  } = useConsentContext();
  const {
    analytics
  } = useAnalyticsContext();
  const [loadingState, setLoadingState] = useState('pending-consent');
  if (loadingState == 'pending-consent' && strictlyNecessary) setLoadingState('ready');
  useReportWebVitals(metric => {
    const pageProps: PageProps = {
      url: window.location.href,
      hostname: window.location.hostname,
      referrer: document.referrer,
      path: window.location.pathname,
      search: window.location.search
    };
    const eventDetails: WebVitalsEvent = {
      id: metric.id,
      name: metric.name,
      delta: metric.delta,
      deltaRounded: Math.round(metric.delta),
      navigationType: metric.source,
      rating: metric.rating,
      value: metric.value,
      valueRounded: Math.round(metric.value)
    };
    if (loadingState == 'ready' && analytics) {
      trackWebVitals(analytics, pageProps, eventDetails);
    }
  });
  return <SpeedInsights sampleRate={Number(process.env.NEXT_PUBLIC_VERCEL_SPEED_SAMPLE_RATE)} data-sentry-element="SpeedInsights" data-sentry-component="SpeedInsightsLoadScript" data-sentry-source-file="index.tsx" />;
}