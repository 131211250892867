import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { sendDriftUserAttributes } from '@/utils/drift/events';
import { useConsentContext } from '@/components/Scripts/OneTrust/ConsentProvider';
import { useAnalyticsContext } from '@/components/Scripts/Segment/AnalyticsProvider';
export function DriftWidget() {
  const router = useRouter();
  const {
    analytics
  } = useAnalyticsContext();
  let {
    functional
  } = useConsentContext();
  const [loadingState, setLoadingState] = useState('pending-consent');
  if (loadingState == 'pending-consent' && functional) setLoadingState('ready');

  // Add listeners for Drift events after script is loaded and send to DataLayer
  useEffect(() => {
    const fireDriftEvent = function (eventName: string, data: any) {
      // Add page properties
      data.hostname = window.location.hostname;
      data.path = window.location.pathname;
      data.referrer = document.referrer;
      data.search = window.location.search;
      data.title = document.title;
      data.url = window.location.href;

      // Fire Segment Event
      try {
        window.analytics.track(eventName, data);
      } catch (error) {
        throw new Error(`Error sending Drift event "${eventName}" to Segment: ${JSON.stringify(error)}`);
      }

      // Fire DataLayer Event
      try {
        window.dataLayer.push({
          event: 'driftInteraction',
          eventCategory: 'drift',
          eventAction: eventName
        });
      } catch (error) {
        throw new Error(`Error sending Drift event "${eventName}" to DataLayer: ${JSON.stringify(error)}`);
      }
    };
    const initDriftEvents = function (api: any) {
      api.on('startConversation', function (data: any) {
        fireDriftEvent('drift_start_convo', data);
      });
      api.on('campaign:open', function (data: any) {
        fireDriftEvent('drift_campaign_open', data);
      });
      api.on('campaign:submit', function (data: any) {
        fireDriftEvent('drift_campaign_submit', data);
      });
      api.on('sidebarOpen', function (e: any) {
        fireDriftEvent('drift_sidebar_open', e.data);
      });
      api.on('sidebarClose', function (e: any) {
        fireDriftEvent('drift_sidebar_close', e.data);
      });
      api.on('conversation:playbookFired', function (data: any) {
        fireDriftEvent('drift_convo_playbook_fired', data);
      });
      api.on('phoneCapture', function (data: any) {
        fireDriftEvent('drift_convo_phone_capture', data);
      });
      api.on('conversation:firstInteraction', function (data: any) {
        fireDriftEvent('drift_convo_first_interaction', data);
      });
      api.on('welcomeMessage:close', function () {
        fireDriftEvent('drift_welcome_close', {});
      });
      api.on('conversation:buttonClicked', function (data: any) {
        fireDriftEvent('drift_convo_btn_click', data);
      });
      api.on('emailCapture', function (e: any) {
        fireDriftEvent('drift_email_capture', e.data);
      });
      api.on('message:sent', function (data: any) {
        fireDriftEvent('drift_message_sent', data);
      });
      api.on('message', function (data: any) {
        fireDriftEvent('drift_message_received', data);
      });
      api.on('scheduling:meetingBooked', function (data: any) {
        fireDriftEvent('drift_scheduling_meeting_booked', data);
      });
    };
    if (loadingState === 'loaded' && window.drift) {
      setLoadingState('initialized');
      window.drift.on('ready', function (api: any) {
        try {
          initDriftEvents(api);
        } catch (error: any) {
          throw new Error(error.message);
        }
        try {
          sendDriftUserAttributes(window.analytics);
        } catch (error: any) {
          throw new Error(error.message);
        }
      });
    }
  }, [analytics, loadingState]);

  // Fire Drift page event on route change
  useEffect(() => {
    const handleRouteChange = () => {
      window.drift.page();
    };
    if (!router.isReady || !window.drift) return;
    router.events.on('routeChangeComplete', handleRouteChange);
    handleRouteChange();
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router]);
  if (loadingState !== 'pending-consent') {
    if (loadingState === 'ready') setLoadingState('loaded');
    return <Script id="drift-script" strategy="lazyOnload" dangerouslySetInnerHTML={{
      __html: `
            "use strict";
            !function() {
            var t = window.driftt = window.drift = window.driftt || [];
            if (!t.init) {
                if (t.invoked) return undefined;
                t.invoked = !0, t.methods = ["identify", "config", "track", "reset", "debug", "show", "ping", "page", "hide", "off", "on"], 
                t.factory = function(e) {
                return function() {
                    var n = Array.prototype.slice.call(arguments);
                    return n.unshift(e), t.push(n), t;
                };
                }, t.methods.forEach(function(e) {
                t[e] = t.factory(e);
                }), t.load = function(t) {
                var e = 3e5, n = Math.ceil(new Date() / e) * e, o = document.createElement("script");
                o.type = "text/javascript", o.async = !0, o.crossorigin = "anonymous", o.src = "https://js.driftt.com/include/" + n + "/" + t + ".js";
                var i = document.getElementsByTagName("script")[0];
                i.parentNode.insertBefore(o, i);
                };
            }
            }();
            drift.SNIPPET_VERSION = '0.3.1';
            drift.load('${process.env.NEXT_PUBLIC_DRIFT_KEY}');
            `
    }} />;
  } else {
    return '';
  }
}